import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import SettingsIcon from "@mui/icons-material/Settings";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { ReducerProps } from "../types";
import Right from "./Right";
import CustomizedHook from "./CustomizedHook";
import { ReducerActionTypes } from "../reducer";
import AppLinks from "./AppLinks";
import ScrollTop from "./ScrollTop";
import Fab from "@mui/material/Fab";
import Title from "./Title";
import ConfigureDialogListItemText from "./ConfigureDialogListItemText";

const drawerWidth = 320;

interface Props extends ReducerProps {}

export default function PermanentDrawerLeft(props: Props) {
  const { reducerState, dispatch } = props;

  React.useEffect(() => {
    dispatch({
      type: ReducerActionTypes.LOAD_DATA,
      payload: {
        jsonRaw: reducerState.jsonRaw,
      },
    });
  }, []);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Title {...props} title="OAG App Viewer"></Title>
        <Divider />
        <List>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ConfigureDialogListItemText {...props} />
            </ListItemButton>
          </ListItem>
        </List>
        <Divider />

        {reducerState.appData && (
          <CustomizedHook
            reducerState={reducerState}
            dispatch={dispatch}
            labels={(reducerState.json as any)?.map(
              (a: any) => a.settings.label
            )}
          />
        )}
        <Divider />
        <AppLinks {...props} />
        <Divider />
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          bgcolor: "background.default",
          p: 3,
        }}
      >
        <span id="back-to-top-anchor"></span>
        <Right {...props}></Right>
      </Box>
      <ScrollTop>
        <Fab size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </Box>
  );
}
