import Typography from "@mui/material/Typography";
import { ReducerProps } from "../types";

interface Props extends ReducerProps {
  title: string;
}

export default function Title(props: Props) {
  const { reducerState, dispatch } = props;

  return (
    <Typography variant="h2" style={{ margin: 30 }} color="text.secondary">
      {props.title}
    </Typography>
  );
}
