
var jq = require('jq-web')

export enum ReducerActionTypes {
  SET_JSON = "SET_JSON",
  LOAD_DATA = "LOAD_DATA",
  FILTER_DATA = "FILTER_DATA",
}

export interface ReducerState {
  jsonRaw?: string
  json?: object[]
  appData?: object[]
  selectedLabels: string[],
  properties: string[]
  selectedProperties: string[]
}

export interface ReducerAction {
  type: ReducerActionTypes
  payload: Partial<ReducerState>
}

// const replaceGroupNames = (data: any[]) => {
//   return data.map((d) => {
//     return {
//       ...d,
//       settings: {
//         ...d.settings,
//         groups: d.settings.groups.map((g: string) => {
//           const group = groups.find((_: { id: string }) => _.id === g)
//           if (group) {
//             return group.profile.name
//           }
//           return g;
//         }),
//       },
//     };
//   });
// }

const _getProperties = (data: any, prefix = "$"): string[] => {

  const skipProps = ["appLinks"]

  const localKeys: string[] = []

  if (typeof data === 'object' && data !== null && data !== undefined) {
    Object.keys(data).forEach((key: string) => {
      if (skipProps.includes(key)) {
        return
      }

      const currentKey = `${prefix}.${key}`

      if (Array.isArray(data[key]) && data[key].length > 0) {
        localKeys.push(..._getProperties(data[key][0], `${currentKey}[]`))
      } else if (typeof data[key] === 'object') {
        localKeys.push(..._getProperties(data[key], currentKey))
      } else {
        localKeys.push(currentKey)
      }
    })
  }

  return localKeys
}

const getProperties = (json: any[]) => {

  let keys: any = []

  json.forEach(data => {
    const _keys = _getProperties(data)
    keys = keys.concat(_keys).filter((s: string, i: number, array: string[]) => array.indexOf(s) === i);
  })

  // keys.sort((a: any, b: any) => a.toLowerCase() > b.toLowerCase() ? 1 : a.toLowerCase() < b.toLowerCase() ? -1 : 0)
  return keys
}

export const reducer: React.Reducer<ReducerState, ReducerAction> = (state, action) => {
  switch (action.type) {
    case ReducerActionTypes.SET_JSON: {
      const jsonRaw = action.payload.jsonRaw || "[]"
      return {
        ...state, jsonRaw
      }
    }
    case ReducerActionTypes.LOAD_DATA: {
      let json = []
      let appData = []
      let properties: string[] = []
      let selectedProperties: string[] = []
      try {
        console.log("hey")
        json = JSON.parse(state.jsonRaw!)
        json.sort((a: any, b: any) => a.settings.label.toLowerCase() > b.settings.label.toLowerCase() ? 1 : a.settings.label.toLowerCase() < b.settings.label.toLowerCase() ? -1 : 0)
        //json = replaceGroupNames(json)
        appData = json
        properties = getProperties(json)
        localStorage.setItem("app", state.jsonRaw!)
      } catch (e) { }
      return {
        ...state,
        json,
        appData,
        selectedLabels: [],
        properties,
        selectedProperties
      }
    }
    case ReducerActionTypes.FILTER_DATA: {
      const selectedLabels = action.payload.selectedLabels || []
      let appData = (state.json as any[]).filter(d => selectedLabels.length === 0 || selectedLabels.includes(d.settings.label));
      return {
        ...state,
        selectedLabels: selectedLabels,
        appData
      }
    }
    default: {
      return state
    }
  }
}

export const reducerInit = (initialData: ReducerState): ReducerState => {
  return {
    ...initialData,
  }
}