import * as React from "react";

import TextField from "@mui/material/TextField/TextField";

import { ReducerAction, ReducerActionTypes, ReducerState } from "../reducer";

interface Props {
  reducerState: ReducerState;
  dispatch: React.Dispatch<ReducerAction>;
}

export default function JsonInputTextField(props: Props) {
  const { reducerState, dispatch } = props;

  const { jsonRaw } = reducerState;

  return (
    <TextField
      fullWidth
      multiline
      maxRows={10}
      label="fullWidth"
      id="fullWidth"
      value={jsonRaw}
      onChange={(e) =>
        dispatch({
          type: ReducerActionTypes.SET_JSON,
          payload: {
            jsonRaw: e.target.value,
          },
        })
      }
    />
  );
}
