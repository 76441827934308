import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import Stack from "@mui/material/Stack";
import Info from "./JsonInputTextField";
import { ReducerProps } from "../types";
import { ReducerActionTypes } from "../reducer";

interface Props extends ReducerProps {}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ConfigureDialogListItemText(props: Props) {
  const [open, setOpen] = React.useState(false);
  const { reducerState, dispatch } = props;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <ListItemText primary="Configure" onClick={handleClickOpen} />
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Configure
            </Typography>
          </Toolbar>
        </AppBar>
        <Stack
          direction="row"
          marginLeft={5}
          marginTop={5}
          marginRight={5}
          marginBottom={5}
        >
          <Info {...props} />
        </Stack>
        <Stack direction="row" marginLeft={5}>
          <Button
            variant="contained"
            onClick={(e) => {
              handleClose();
              dispatch({
                type: ReducerActionTypes.LOAD_DATA,
                payload: {
                  jsonRaw: reducerState.jsonRaw,
                },
              });
            }}
          >
            Load JSON
          </Button>
        </Stack>
      </Dialog>
    </React.Fragment>
  );
}
