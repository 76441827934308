import "./App.css";
import { useReducer } from "react";
import { ReducerState, reducer, reducerInit } from "./reducer";
import Main from "./components/Main";

export default function App() {
  const reducerInitialState: ReducerState = {
    json: [],
    jsonRaw: localStorage.getItem("app") || "",
    appData: [],
    selectedLabels: [],
    properties: [],
    selectedProperties: [],
  };

  const [reducerState, dispatch] = useReducer(
    reducer,
    reducerInitialState,
    reducerInit
  );

  return <Main reducerState={reducerState} dispatch={dispatch} />;
}
