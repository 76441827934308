import List from "@mui/material/List";
import { ReducerProps } from "../types";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText/ListItemText";

interface Props extends ReducerProps {}

export default function AppLinks(props: Props) {
  const { reducerState, dispatch } = props;
  return (
    <List>
      {reducerState.appData &&
        (reducerState.appData as any[]).map((d) => {
          return (
            <ListItem key={d.settings.label} disablePadding>
              <ListItemButton href={`#${d.settings.label}`}>
                <ListItemText
                  primaryTypographyProps={{ fontSize: "small" }}
                  primary={`${d.settings.label} (${d.policies.length})`}
                />
              </ListItemButton>
            </ListItem>
          );
        })}
    </List>
  );
}
