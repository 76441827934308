import Grid from "@mui/material/Grid";
import { ReducerProps } from "../types";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import React from "react";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import { CopyBlock, dracula } from "react-code-blocks";

interface Props extends ReducerProps {}

export default function Right(props: Props) {
  const { reducerState, dispatch } = props;
  return (
    <>
      <Grid
        item
        sm={12}
        md={7}
        lg={8}
        sx={{
          display: "flex",
          flexDirection: "column",
          maxWidth: "100%",
          width: "100%",
          backgroundColor: { xs: "transparent", sm: "background.default" },
          alignItems: "start",
          pt: { xs: 2, sm: 4 },
          px: { xs: 2, sm: 10 },
          gap: { xs: 4, md: 8 },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            width: "100%",
            maxWidth: { sm: "100%", md: "100%" },
            gap: { xs: 5, md: "none" },
          }}
        >
          {reducerState.appData &&
            (reducerState.appData! as object[]).map((d: any) => {
              return (
                <React.Fragment>
                  <Stack spacing={2} useFlexGap>
                    <Typography variant="h3" id={d.settings.label}>
                      {d.settings.label}
                    </Typography>
                    <Stack direction="row" spacing={1}>
                      <Chip
                        label={d.policies.length + " policies"}
                        color="primary"
                        variant="outlined"
                      />
                      <Chip
                        label={d.settings.groups.length + " groups"}
                        color="primary"
                        variant="outlined"
                      />
                      <Chip
                        label={d.headers.length + " attributes"}
                        color="primary"
                        variant="outlined"
                      />
                    </Stack>
                    <Stack direction="row" spacing={1}>
                      {d.settings.groups.map((g: any) => {
                        return (
                          <Chip label={g} color="success" variant="outlined" />
                        );
                      })}
                    </Stack>
                    <Stack direction="row" spacing={1}>
                      {d.headers.map((h: any) => {
                        return (
                          <Chip
                            label={h.header + ":" + h.value}
                            color="warning"
                            variant="outlined"
                          />
                        );
                      })}
                    </Stack>
                    {d.policies.map((p: any, i: number) => {
                      return (
                        <React.Fragment>
                          <Stack direction="row" spacing={1}>
                            <Typography variant="h6">
                              {i + 1}. {p.resource} - <code>{p.rule}</code>
                            </Typography>
                          </Stack>
                          {p.custom && (
                            <CopyBlock
                              text={p.custom}
                              language={"nginx"}
                              showLineNumbers={true}
                              theme={dracula}
                            />
                          )}
                        </React.Fragment>
                      );
                    })}
                  </Stack>
                </React.Fragment>
              );
            })}
        </Box>
      </Grid>
    </>
  );
}
